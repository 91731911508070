import React, { useMemo, useRef, useState } from "react";
import { ActionButton } from "./action-button";
import "./mailing-list.scss";
import { getItNow, subscribeGhost, validateEmail } from "../utils/utils";
import cn from "classnames";

interface Props {
  noBg?: boolean;
  text?: string;
  formId?: string;
}

export const MailingList = ({ noBg, text, formId }: Props) => {
  const submitRef = useRef<HTMLInputElement | null>(null);
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const isEmailValid = useMemo(() => validateEmail(email), [email]);

  const completed = () => {
    // setEmail("");
    setSubmitted(true);
  };

  const submit = (e: any) => {
    e.preventDefault();
    if (isEmailValid) {
      if (formId) {
        console.log("FORM ID");
        getItNow(email, formId);
      }
      console.log(e.target);
      subscribeGhost(email);
      completed();
    }
  };

  return (
    <>
      <div
        className={cn("mailing-list--wrapper-meta", {
          "mailing-list--form--complete": submitted,
        })}
      >
        <div className="complete--message">
          Check your email to confirm subscription!
        </div>
        <form className={"mailing-list--wrapper"} onSubmit={submit}>
          <div className="mailing-list--form">
            <input
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="text"
              placeholder="astro@example.com"
            />
            <ActionButton
              text={text || "Subscribe"}
              variant="solid"
              colorScheme={isEmailValid ? "primary" : "third"}
              isDisabled={!isEmailValid}
              onClick={() => {
                submitRef.current?.click();
              }}
            />
          </div>
          <input
            type="submit"
            value="test"
            ref={submitRef}
            className="submit-button"
          />
        </form>
      </div>
      {/* <div
        className="ghost--signup"
        dangerouslySetInnerHTML={{
          __html: `
          src="https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js"
          data-label-1="website"
          data-button-color="#f6921e"
          data-button-text-color="#FFFFFF"
          data-site="https://signal.hyperbrew.co/"
          async
          `,
        }}
      ></div> */}
    </>
  );
};
